import React from 'react';
import styled from 'styled-components';

const InputWrapper = styled.div`
  display: flex;
  margin: ${props => props.margin};
  @media only screen and (max-width: 640px) {
    display: block;
  }
`;

const Label = styled.label`
  font-size: 1.8rem;
  flex: 0 0 20rem;
  margin-top: 0.9rem;
  font-weight: 700;
  color: var(--color-heading-dark);
  @media only screen and (max-width: 640px) {
    display: block;
    margin-bottom: 0.4rem;
  }
`;

const TextInput = styled.input`
  flex: 1;
  width: 100%;
  height: 4rem;
  padding: 0 2.4rem;
  background: #F0F3F2;
  outline: none;
  border: none;
  border-radius: 0.6rem;
`;

const Textarea = styled.textarea`
  flex: 1;
  width: 100%;
  padding: 1rem 2.4rem;
  background: #F0F3F2;
  outline: none;
  border: none;
  border-radius: 0.6rem;
`;

const Input = ({ type, labelText, placeholder='', name='', required=false, margin='0' }) => {
    let input = null;

    switch (type) {
        default:
        case 'text':
            input = <TextInput type="text" placeholder={placeholder} name={name} required={required} />
            break;
        case 'email':
            input = <TextInput type="email" placeholder={placeholder} name={name} required={required} />
            break;
        case 'textarea':
            input = <Textarea placeholder={placeholder} name={name} required={required} rows='9' />
            break;
    }

    return (
        <InputWrapper margin={margin}>
            {labelText ? <Label>{labelText}</Label> : null}
            {input}
        </InputWrapper>
    )
}

export default Input;
