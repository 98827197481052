import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import ContentBackground from '../assets/img/contact-us-background.jpg';
import Input from '../components/Input/Input';
import { Link } from 'gatsby';

const ContentWrapper = styled.section`
  padding: 16rem 2.4rem;
  background: url(${ContentBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface);
`;

const Content = styled.div`
  max-width: var(--page-content-width);
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 6.4rem;
  grid-template-columns: repeat(3, 1fr);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 4.8rem;
  border-radius: var(--border-radius-surface);
  grid-column: 1 / 3;
  @media only screen and (max-width: 1200px) {
    grid-column: 1 / 4;
  }
  @media only screen and (max-width: 640px) {
    padding: 2.4rem;
  }
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
  p {
    flex: 1;
    text-align: right;
  }
  button {
    margin-left: 2.4rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 15rem;
    height: 2.73em;
    padding: 0 1.82em;
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--color-body-dark);
    background: var(--color-green);
    border: none;
    border-radius: 0.27em;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    :hover {
      box-shadow: var(--shadow-element);
    }
  }
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    button {
      margin-left: 0;
      margin-top: 2.4rem;
      width: 100%;
    }
  }
`;

const ContactInfo = styled.div`
  h3 {
    color: var(--color-heading-light);
  }
  p {
    color: var(--color-body-light);
    :not(:last-child) {
      margin-bottom: 6.4rem;
    }
  }
  a {
    position: relative;
    font-weight: bold;
    color: #ffffff;
    transition: opacity 0.15s ease-in-out;
    ::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.2rem;
      width: 100%;
      height: 0.2rem;
      background: #fff;
      transform: translateY(0);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
    :hover ::after {
      opacity: 1;
      transform: translateY(0.2rem);
    }
  }
  @media only screen and (max-width: 1200px) {
    grid-column: 1 / 4;
  }
`;

const ContactUsPage = () => {
  const form = useRef(null);
  const [message, setMessage] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let data = new FormData(form.current);

    var xhr = new XMLHttpRequest();
      xhr.open('POST', '../mailer.php');
      xhr.onload = function() {
        if (xhr.status === 200) {
          if (xhr.responseText === 'true') {
            setMessage('Email sent!');
            form.current.reset();
          } else {
            setMessage('Something went wrong, email not sent!');
          }          
        } else {
          setMessage('Something went wrong, email not sent!');
        }
      }
      xhr.send(data);
  }

  return (
    <ContentWrapper>
      <Content>
          <h1>Contact us</h1>
          <p className='subtitle'>Get in touch and let us know how we can help</p>
          <Grid>
            <Form id='contact-form' action='' method='POST' ref={form} onSubmit={handleFormSubmit}>
              <Input labelText='Name' name='name' placeholder='John Doe' required margin='0 0 2.4rem 0' />
              <Input labelText='Email' name='email' type='email' placeholder='email@example.com' required margin='0 0 2.4rem 0' />
              <Input labelText='Company name' name='company' placeholder='ARVRtech' required margin='0 0 2.4rem 0' />
              <Input labelText='About your idea' name='message' type='textarea' placeholder='Tell us more about your project...' required />
              <FormFooter>
                <p>{message}</p>
                <button type='submit'>Send</button>
              </FormFooter>
            </Form>

            <ContactInfo>
              <h3>Need help getting started?</h3>
              <p>Check out our <Link to='/support-center/'>FAQ</Link>.</p>
              
              <h3>General contact</h3>
              <p>info@arvrtech.eu</p>

              <h3>Technical support</h3>
              <p>support@arvrtech.eu</p>
            </ContactInfo>
          </Grid>
      </Content>
    </ContentWrapper>
  )
}

export default ContactUsPage;
